@import "../../styles/constants.scss";

$neutralColorGradient: radial-gradient(circle, $neutralColorStart 35%, rgba($neutralColorEnd, 0) 100%);

@mixin size-bounds {
	max-width: 750px;
	max-height: 750px;
	min-width: 250px;
	min-height: 250px;
}

.AuraBounds {
	@include size-bounds;
}

.ChatAuraContainer{
	width: 100%;
	height: 100%;
	@include size-bounds;
	position: relative;
	.ChatAuraProfile {
		width: 65%;
		height: 65%;
		border-radius: 50%;
		content: "";

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 2;

		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100%;
		opacity: 0.9;
	}
	.ChatAura {
		.ChatAuraItem {
			.NeutralItem, .GoodItem, .BadItem {
				animation: aura 10s infinite linear;
				border-radius: 50%;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				width: 100%;
				height: 100%;
				filter: blur(1vw);
			}
			.NeutralItem {
				background: $neutralColorGradient;
				animation-delay: 0s;
			}
			.GoodItem {
				background-color: $goodColor;
				animation-delay: -10s;
			}
			.BadItem {
				background-color: $badColor;
				animation-direction: reverse;
				animation-delay: -20s;
			}
		}
	}
}

.fadedIn {
	opacity: 1 !important;
	animation-fill-mode:forwards;
	animation: fadeIn 3s linear;
}

.fadedOut {
	opacity: 0.1 !important;
	animation-fill-mode:forwards;
	animation: fadeOut 3s linear;
}

@keyframes aura {
	25%,
	75% {
		border-radius: 60% 40% 40% 60% / 60% 40% 60% 40%;
	}
	50% {
		width: 90%;
		border-radius: 40% 60% 60% 40% / 40% 60% 40% 60%;
	}
	100% {
		transform: rotate(360deg);
	}
}


@keyframes fadeIn {
	0% {
		opacity: 0.1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.1;
	}
}