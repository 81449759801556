@import "../styles/constants.scss";

.TwitchChatItem {
  background-color: #18181b;
  color: #efeff1;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  .TwitchChatUsername {
    font-weight: 700;
  }
  .TwitchChatTimestamp {
    color: #adadb8;
  }
  .TwitchChatImage {
    height: 28px;
    width: 28px;
  }
}

@keyframes slideInUp{
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}